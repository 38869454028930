import React, {
  ChangeEvent,
  FC,
  FormEvent,
  ReactNode,
} from 'react';

import Color from 'color'
import { motion } from 'framer-motion';
import { styled } from '@mui/material';

import {
  AnchorButton,
  Button,
  Card,
  Container,
  Form,
  Heading,
  Input,
  LicenseInput,
  Paragraph,
  SubmitButton,
} from '@domain/components';
import { ContentStructureFormBlock, FormDataCreation, InputName, Paragraphs, RedirectUrls } from '@domain/interfaces';
import { Colors, Scaling } from '@domain/theming';
import { useWindowSize } from '@domain/hooks';

const FormCard = styled(motion(Card) as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
      background-image: linear-gradient(
        358deg,
        ${props => props.theme.isThemed ? props.theme.palette.secondary.main : Colors.GREY_LIGHT} -20%,
        ${props => props.theme.isThemed ? Color(props.theme.palette.secondary.main).lighten(0.03).hex() : Color(Colors.GREY_LIGHT).lighten(0.1).hex()} 25%,
        ${props => props.theme.isThemed ? props.theme.palette.secondary.main : Colors.GREY_LIGHT} 65%
        );
      border: 3px solid ${Colors.WHITE};
      border-radius: 0px;
      flex-grow: 0;
      width: 100%;
      position:relative;
      max-width: ${props => Scaling.scaleUpTo4K(520, props.$vwWidth)}px;
      padding: 0;
      margin: auto;
      align-self: flex-start;
      align-items: flex-end;
      opacity: .85;
      will-change: transform, opacity;
      &.card-form-grey {
        background-image: linear-gradient(
          358deg,
          ${Colors.GREY_LIGHT} -10%,
          ${Color(Colors.GREY_LIGHT).lighten(0.06).hex()} 33%,
          ${Colors.GREY_LIGHT} 55%
          );
          .button {
            border-color: ${Colors.BLACK};
            color: ${Colors.BLACK};
            &:hover {
              background: ${Color(Colors.BLACK).rgb().alpha(0.08).string()}
            }
          }
      }
  
      &.single-card {
        min-height:  ${props => Scaling.scaleUpTo4K(468, props.$vwWidth)}px;
        height: 0; //IE
      }
      + .card {
        margin-top: 60px;
        margin-bottom:30px;
      }
      p, h3, input, label {
      }
      @media screen and (min-width:1920px) {
        &.large-width-out {
          max-width: ${props => Scaling.scaleUpTo4K(650, props.$vwWidth)}px;
        }
      }
      @media screen and (max-width: 900px) {
        + .card {
          margin-top: 40px;
          margin-bottom:30px;
        }
      }
      @media screen and (max-width: 420px) {
        &.card.card-form {
          padding: 0;
        }
      }
    `;

const FormCardContainer = styled(
  motion(Container) as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
      padding: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px ${props => Scaling.scaleUpTo4K(40, props.$vwWidth)}px;
      will-change: transform, opacity;
      + .card-form__container--response {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .card-form__button--redirect {
        max-width: 100%;
        text-align: center;
        + .card-form__button--redirect {
          margin-top: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
        }
      }
      h4, h5 {
        // color: ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.contrastText};
      }
    `;

const CaseForm = styled(motion(Form) as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 auto;
    max-width: ${props => Scaling.scaleUpTo4K(500, props.$vwWidth)}px;
    padding: 0;
    // justify-content: space-between;
    .card-form & .button--submit {
      margin-top: auto;
    }
    > p,
    > ul,
    > h3 {
      max-width: 100%;
    }
    ul {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
      padding-left: ${props => Scaling.scaleUpTo4K(18, props.$vwWidth)}px;
      padding-bottom: ${props => Scaling.scaleUpTo4K(15, props.$vwWidth)}px;
      font-size: ${props => Scaling.textFontSize(13, props.$vwWidth)}px;
    }
    @media screen and (min-width:1920px) {
      &.large-width {
        max-width: ${props => Scaling.scaleUpTo4K(610, props.$vwWidth)}px;
      }
    }
  `;

const StyleCarddHeadingWithCloseButton = styled(Heading as any, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
  flex:1 0 auto;
  margin-left: ${props => Scaling.scaleUpTo4K(40, props.$vwWidth)}px;
`;

const StyledCloseButton = styled(Button as any)`
  .card-form__header-group & {
    flex: 0 0 auto;
    width: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
    height: ${props => Scaling.scaleUpTo4K(30, props.$vwWidth)}px;
    padding: 0;
    margin: 0 0 0 auto;
    border-radius: ${props => Scaling.scaleUpTo4K(10, props.$vwWidth)}px;
    box-shadow: none;
  }
`;
const formVariants = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      opacity: {
        duration: 0.68,
        delay: 1.03,
      },
      y: {
        delay: 0.96,
        type: 'spring',
        stiffness: 40,
      }
    },
  }
}

const formInnerVariants = {
  hidden: {
    opacity: 0,
    y: '20%',
    transition: {
      duration: 0.12,
      y: {
        delay: 0.12,
      },
    },
    transitionEnd: {
      zIndex: 0,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    zIndex: 1,
    transition: {
      type: 'spring',
      stiffness: 30,
      mass: 0.2,
      opacity: {
        delay: 0.22,
        duration: 0.18,
      },
    },
  },
};

const formChildVariants = {
  hidden: {
    opacity: 0,
    y: 200,
    transition: {
      duration: 1,
    }
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: .3,
      delay: .3,
    }
  }
};

interface Props {
  verifyAndSubmit: (event?: FormEvent<HTMLFormElement>) => void;
  onChangeCreation: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlurLicensePlate: () => void;
  onBlurEmail: () => void;
  handleResetCreation: () => void;
  shouldShowError: (name: InputName, blurred: boolean) => boolean;
  showError: (name: InputName, blurred: boolean) => ReactNode
  formDataCreation: FormDataCreation;
  redirectUrls: RedirectUrls;
  emailCreationBlurred: boolean;
  licenseCreationBlurred: boolean;
  errors: Record<string, string> | null;
  content: ContentStructureFormBlock
}

const FormDI: FC<Props> = ({ verifyAndSubmit, onChangeCreation, onBlurLicensePlate, onBlurEmail, handleResetCreation, shouldShowError, showError, formDataCreation, redirectUrls, licenseCreationBlurred, emailCreationBlurred, errors, content }) => {

  const { vwWidth } = useWindowSize();

  const { licensePlateCreation, customerEmailCreation } = formDataCreation;

  const contentCardOne = content['card-one']
  const contentCardTwo = content['card-two']

  const { button: buttonCardOne } = contentCardOne
  const { button: buttonCardTwo, title: titleCardTwo, subtitle: subtitleCardTwo, paragraphs: paragraphsCardTwo } = contentCardTwo

  const paragraphs = paragraphsCardTwo ? paragraphsCardTwo.map((item: Paragraphs, i: number) => {
    return (
      <Paragraph textAlign="center" key={i}>
        {item.text}
      </Paragraph>
    )
  }) : null

  return (
    <FormCard
      className="card-form single-card"
      shadow={4}
      variants={formVariants}
      initial="hidden"
      animate="visible"
      $vwWidth={vwWidth}
    >
      <FormCardContainer
        className="card-form__container--fields"
        flexDirection="column"
        flexGrow="1"
        flexShrink="1"
        width="100%"
        variants={formInnerVariants}
        initial={false}
        animate={!redirectUrls.urlCreation ? 'visible' : 'hidden'}
        $vwWidth={vwWidth}
      >
        <CaseForm onSubmit={verifyAndSubmit} $vwWidth={vwWidth}>
          <LicenseInput
            onChange={onChangeCreation}
            value={licensePlateCreation}
            name="licensePlateCreation"
            type="text"
            label="Kenteken"
            onBlur={onBlurLicensePlate}
            error={shouldShowError('licensePlateCreation', licenseCreationBlurred)}
            dynamicFontSize={true}
          />
          {showError('licensePlateCreation', licenseCreationBlurred)}
          <Input
            placeholder="voorbeeld@domain.com"
            name="customerEmailCreation"
            onChange={onChangeCreation}
            type="email"
            label="E-mailadres"
            onBlur={onBlurEmail}
            error={shouldShowError('customerEmailCreation', emailCreationBlurred)}
            value={customerEmailCreation || ''}
            dynamicFontSize={true}
          />
          {showError('customerEmailCreation', emailCreationBlurred)}
          {buttonCardOne && <SubmitButton
            value="Jouw opties"
            disabled={!!redirectUrls.urlCreation || !!errors}
            dynamicFontSize={true}
          >
            <span>{buttonCardOne}</span>
          </SubmitButton>}
        </CaseForm>
      </FormCardContainer>
      <FormCardContainer
        className="card-form__container--response"
        flexDirection="column"
        flexGrow="1"
        flexShrink="0"
        width="100%"
        variants={formInnerVariants}
        initial={false}
        animate={redirectUrls.urlCreation ? 'visible' : 'hidden'}
        $vwWidth={vwWidth}
      >
        <Container
          className="card-form__header-group"
          flexGrow="0"
          marginBottom={Scaling.scaleUpTo4K(30, vwWidth) + 'px'}
          alignItems="center"
          justifyContent="center"
        >
          {titleCardTwo && <StyleCarddHeadingWithCloseButton
            level={3}
            textAlign="center"
            $vwWidth={vwWidth}
          >
            {titleCardTwo}
          </StyleCarddHeadingWithCloseButton>}
          <StyledCloseButton
            variant="outline"
            onClick={handleResetCreation}
            $vwWidth={vwWidth}
          >
            X
          </StyledCloseButton>
        </Container>
        {subtitleCardTwo && <Heading
          level={5}
          textAlign="center"
          initial={true}
          animate={!redirectUrls.urlCreation ? 'hidden' : 'visible'}
          variants={formChildVariants}
        >
          {subtitleCardTwo}
        </Heading>}
        {paragraphsCardTwo && <>{paragraphs}</>}
        {buttonCardTwo && <AnchorButton
          variant="outline"
          target="_blank"
          className="card-form__button--redirect"
          href={redirectUrls.urlCreation || ''}
          dynamicFontSize={true}
        >
          <span>{buttonCardTwo}</span>
        </AnchorButton>}
      </FormCardContainer>
    </FormCard>
  )
}

export default FormDI
