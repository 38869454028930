import React, { useState, FC, useMemo, memo, useCallback, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
  nlNL
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { isMobile } from 'react-device-detect';
import { Colors, Scaling } from '@domain/theming';
import { useWindowSize, useInputBlur } from '@domain/hooks';
import { nl } from 'date-fns/locale'
import moment from 'moment';
interface Props {
  label: string;
  onChange: (
    date: Date | null,
    value?: string | null | undefined,
  ) => void;
  value: string;
  vwWidth?: any;
  onBlur: () => void;
  error: boolean;
  dynamicFontSize?: boolean;
}

interface StyledProps {
  label: string;
  onChange: (
    date: Date | null,
    value?: string | null | undefined,
  ) => void;
  value: Date | null
  $vwWidth?: any;
  onBlur: () => void;
  error: boolean;
  $dynamicFontSize?: boolean;
}

const PREFIX = 'index';

const classes = {
  textField: `${PREFIX}-textField`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  inputError: `${PREFIX}-inputError`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelShrink: `${PREFIX}-inputLabelShrink`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  inputLabelClasses: `${PREFIX}-inputLabelClasses`,
  inputDisabled: `${PREFIX}-inputDisabled`,
  inputLabelDisabled: `${PREFIX}-inputLabelDisabled`,
  focused: `${PREFIX}-focused`,
  inputLabelError: `${PREFIX}-inputLabelError`,
  dense: `${PREFIX}-dense`,
  menu: `${PREFIX}-menu`
};

const StyledMuiDatePicker = styled(MuiDatePicker, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})((props: Partial<StyledProps>) => ({
  [`&.${classes.textField}`]: {
    //main container
    width: '100%',
    margin: props.$dynamicFontSize ? Scaling.scaleUpTo4K(20, props.$vwWidth) + 'px 0' : '20px 0',
    paddingTop: props.$dynamicFontSize ? Scaling.scaleUpTo4K(22, props.$vwWidth) + 'px' : '22px',
    height: 'auto',
  },

  [`& .${classes.inputRoot}`]: {
    //input container
    fontFamily: 'Gabriel Sans',
    borderColor: Colors.BLACK,
    color: Colors.BLACK,
    marginTop: 0
  },

  [`& .${classes.inputInput}`]: {
    textAlign: 'center',
    padding: isMobile
      ? '0 0 0 0' : '0 0 0 48px',
    fontSize:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(32, props.$vwWidth)
          : Scaling.textFontSize(32, props.$vwWidth)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(36, props.$vwWidth)
          : Scaling.textFontSize(36, props.$vwWidth)) + 'px' :
        '36px',
    height:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(48, props.$vwWidth)
          : Scaling.textFontSize(48, props.$vwWidth)) + 'px' :
        '48px',
    '&::placeholder': {
      fontSize:
        props.$dynamicFontSize ?
          (isMobile
            ? Scaling.textFontSizeMobile(16, props.$vwWidth)
            : Scaling.textFontSize(16, props.$vwWidth)) + 'px' :
          '16px',
    }
  },

  [`& .${classes.inputError}`]: {
    color: Colors.RED,
  },

  [`& .${classes.inputLabelRoot}`]: {
    height:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(48, props.$vwWidth)
          : Scaling.textFontSize(48, props.$vwWidth)) + 'px' :
        '48px',
    maxWidth: '100%',
    top: 'calc(50% + 20px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // padding: '6px 0 7px',
    color: Colors.BLACK,
    fontFamily: 'inherit',
    fontSize:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(32, props.$vwWidth)
          : Scaling.textFontSize(32, props.$vwWidth)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(36, props.$vwWidth)
          : Scaling.textFontSize(36, props.$vwWidth)) + 'px' :
        '36px',
    letterSpacing: '0.03125em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    '&$focused': {
      color: Colors.BLACK,
      fontWeight: 'bold',
    },
    '&$inputLabelError': {
      color: Colors.BLACK,
    },
    '&+$inputRoot': {
      marginTop: 0,
    },
  },

  [`& .${classes.inputLabelShrink}`]: {
    top: 0,
    left: 0,
    transform: 'translate(0, 0) scale(1)',
    color: Colors.BLACK,
    lineHeight: '14px',
    letterSpacing: '0.0625em',
    fontSize:
      props.$dynamicFontSize ?
        (isMobile
          ? Scaling.textFontSizeMobile(16, props.$vwWidth)
          : Scaling.textFontSize(16, props.$vwWidth)) + 'px' :
        '16px',
    // transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  },

  [`& .${classes.inputUnderline}`]: {
    '&$inputError': {
      '&:before': {
        borderColor: Colors.RED,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${Colors.RED}`,
      },
      '&:after': {
        borderBottom: `2px solid ${Colors.RED}`,
      },
    },
    '&:before': {
      borderColor: Colors.BLACK,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${Colors.BLACK}`,
    },
    '&:after': {
      borderBottom: `2px solid ${Colors.BLACK}`,
    },
  },

  [`& .${classes.inputLabelClasses}`]: {},
  [`& .${classes.inputDisabled}`]: {},
  [`& .${classes.inputLabelDisabled}`]: {},
  [`& .${classes.focused}`]: {},
  [`& .${classes.inputLabelError}`]: {
    color: Colors.BLACK
  },
  [`& .${classes.dense}`]: {
    marginTop: 19,
  },

  [`& .${classes.menu}`]: {
    width: 200,
  }
}));


const DatePicker: FC<Props> = ({ dynamicFontSize, label, onBlur, onChange, value, error }) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);

  const formatDate = (value: Date) =>
    moment(value).format('YYYY-MM-DDT12:00:00.000Z')

  const onChangeValue = (date: unknown) => {
    if (!date) {
      return onChange(date as null, null)
    }
    const formattedDate = formatDate(date as Date)
    return onChange(date as Date, formattedDate)
  }

  useInputBlur(inputEl, onBlur);

  const setInputRef = useCallback((el: HTMLInputElement) => setInputEl(el), []);

  const { vwWidth } = useWindowSize();


  const inputProps = useMemo(
    () => ({
      classes: {
        root: classes.inputRoot,
        underline: classes.inputUnderline,
        input: classes.inputInput,
        disabled: classes.inputDisabled,
        error: classes.inputError,
      },
      placeholder: 'dd-mm-jjjj',
    }),
    [classes],
  );

  const inputLabelProps = useMemo(
    () => ({
      classes: {
        root: classes.inputLabelRoot,
        shrink: classes.inputLabelShrink,
        focused: classes.focused,
        disabled: classes.inputLabelDisabled,
        error: classes.inputLabelError,
      },
      shrink: true,
    }),
    [classes],
  );
  const nlLocale = nlNL.components.MuiLocalizationProvider.defaultProps.localeText;
  const customLocaleText = {
    fieldYearPlaceholder: () => 'jjjj',
    fieldMonthPlaceholder: () => 'mm',
    fieldDayPlaceholder: () => 'dd',
  }
  return (
    <LocalizationProvider
      adapterLocale={nl}
      localeText={{ ...nlLocale, ...customLocaleText }}
      dateAdapter={AdapterDateFns}
    >
      <StyledMuiDatePicker
        inputRef={setInputRef}
        className={classes.textField}
        disableFuture
        format="dd-MM-yyyy"
        label={label}
        $vwWidth={vwWidth}
        $dynamicFontSize={dynamicFontSize}
        value={value as unknown as Date || null}
        onChange={onChangeValue}
        slotProps={
          {
            // inputLabel: {

            // },
            textField: {
              placeholder: 'dd-mm-jjjj',
              error: error,
              variant: 'standard',
              // invalidDateMessage: "",
              margin: "normal",
              required: true,
              classes: {
                root: classes.textField
              },
              InputLabelProps: inputLabelProps,
              // maxDateMessage: "De datum mag niet in de toekomst liggen.",
              InputProps: inputProps
            }
          }
        }
      />
    </LocalizationProvider>
  );
};

export default memo(DatePicker);
